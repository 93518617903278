import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { UserInformations } from "../../../models/userInformationsModel";
import { selectItem } from "../../../services/detailPage/detailPageSlice";
import { useAppSelector } from "../../../app/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useFetchDesignItemQuery } from "../../../services/wcbApi/wcbApiSlice";
import Loader from "../../common/Loader";
import Message from "../../common/Message";

interface DesignItemWithPictureProps {
  userInformations: UserInformations | undefined;
}

const DetailPage: FC<DesignItemWithPictureProps> = ({ userInformations }) => {
  const navigate = useNavigate();
  const { id, itemId } = useParams();

  const {
    data: designItemData,
    error: designItemError,
    isLoading: isLoadingDesignItem,
    isFetching: isFetchingDesignItem,
    isSuccess: isSuccessDesignItem,
  } = useFetchDesignItemQuery(itemId ? itemId : skipToken);

  const designItem = useAppSelector(selectItem);

  useEffect(() => {
    if (
      designItemData &&
      Number(designItemData.designItem.version) >= 6 &&
      userInformations?.userIds.licenseId! &&
      userInformations.userIds.userId!
    ) {
      window.dataLayer.push({
        "event": "wcb_open_to_desktop_button_shown",
        "license_id": userInformations.userIds.licenseId,
        "user_id": userInformations.userIds.userId,
        "con_design_item_id": designItemData.designItem.conDesignItemId!,
        "con_design_set_id": designItemData.designItem.conDesignSetId!,
      });
    }
  }, [designItem, userInformations, designItemData]);

  const openDesktopAppHandler = () => {
    navigate("openConnection");
    window.open(`ideacon:${designItemData?.ideaConProjectUrl}`);
    if (userInformations?.userIds.licenseId! && userInformations.userIds.userId) {
      window.dataLayer.push({
        "event": "wcb_open_to_desktop_initiated",
        "license_id": userInformations.userIds.licenseId,
        "user_id": userInformations.userIds.userId,
        "con_design_item_id": designItemData?.designItem.conDesignItemId!,
        "con_design_set_id": designItemData?.designItem.conDesignSetId!,
      });
    }
  };

  const version = Number(designItemData?.designItem.version);

  const onCloseDetailPage = () => {
    navigate(`/set/${id}`);
  };

  return (
    <div className="detailPage">
      {isLoadingDesignItem || isFetchingDesignItem ? <Loader /> : ""}

      {designItemError ? <Message message="Design item was not found, try again later" /> : ""}

      {isSuccessDesignItem && !isLoadingDesignItem ? (
        <>
          <div className="close-icon">
            <AiOutlineClose onClick={onCloseDetailPage} size={25} />
          </div>
          <div className="detailPage-image-wrapper">
            <img
              src={designItemData.pictureUrl}
              alt={designItemData.designItem.name || "design-item"}
            />
          </div>

          <div className="detailPage-properties">
            <div className="detailPage-properties-top">
              <h2 className="detailPage-heading">
                {designItemData.designItem.name.charAt(0).toUpperCase() +
                  designItemData.designItem.name?.slice(1).toLocaleLowerCase()}
              </h2>

              {version && version >= 6 ? (
                <button className="detailPage-properties-button" onClick={openDesktopAppHandler}>
                  Open in Idea Connection
                </button>
              ) : (
                ""
              )}
            </div>

            <p className="detailPage-description">
              <strong>Design code:</strong>
              {" " + designItemData.designItem.designCode}
            </p>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DetailPage;
