import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/layout/header";
import Home from "./components/pages/home";
import "./css/style.css";
import Footer from "./components/layout/footer";
import OpenConnection from "./components/pages/openConnection";
import {
  useFetchDesignSetsQuery,
  useFetchUserInformationsQuery,
  useFetchUserQuery,
} from "./services/wcbApi/wcbApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  selectEnvironmentType,
  setEnvironmentType,
} from "./services/environmentType/environmentTypeSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import DesignItems from "./components/layout/designItems";
import Loader from "./components/common/Loader";
import DetailPage from "./components/layout/detailPage";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function App() {
  const environmentType = useAppSelector(selectEnvironmentType);

  const dispatch = useAppDispatch();

  const {
    data: userInformationsData,
    isLoading: isLoadingUserInformations,
    isFetching: isFetchingUserInformations,
    error: userInformationsError,
  } = useFetchUserInformationsQuery();

  const {
    data: designSetsData,
    isLoading: isLoadingDesignSets,
    isFetching: isFetchingDesignSets,
    isSuccess: isSuccessDesignSets,
  } = useFetchDesignSetsQuery(userInformationsData?.isAuthenticated ? false : skipToken);

  const { data: userData } = useFetchUserQuery(
    userInformationsData?.isAuthenticated ? false : skipToken,
  );

  const urlString = window.location.href;

  useEffect(() => {
    if (urlString.indexOf("staging") > -1) {
      dispatch(setEnvironmentType("staging"));
    } else if (urlString.indexOf("develop") > -1) {
      dispatch(setEnvironmentType("develop"));
    } else if (urlString.indexOf("localhost") > -1) {
      dispatch(setEnvironmentType("local"));
    } else {
      dispatch(setEnvironmentType("production"));
    }
  }, [dispatch, urlString]);

  useEffect(() => {
    if (
      urlString.indexOf("staging") > -1 ||
      urlString.indexOf("develop") > -1 ||
      urlString.indexOf("localhost") > -1
    ) {
      window.dataLayer.push({
        "event": "react_init",
        "environment_type": "staging",
      });
    } else {
      window.dataLayer.push({
        "event": "react_init",
        "environment_type": "production",
      });
    }
  }, [environmentType, urlString]);

  return (
    <Router>
      <div className="App">
        <Header
          userInformationsData={userInformationsData}
          user={userData}
          designSetsData={designSetsData}
        />
        {isFetchingDesignSets || isLoadingDesignSets ? <Loader /> : ""}

        <Routes>
          {!userInformationsData?.isAuthenticated ? (
            <Route path="/" element={<Home userInformationsData={userInformationsData} />}></Route>
          ) : (
            ""
          )}
          {designSetsData && isSuccessDesignSets ? (
            <Route path="/" element={<Home userInformationsData={userInformationsData} />}>
              <Route path="/" element={<Navigate replace to={`/set/${designSetsData[0].id}`} />} />

              <Route
                path="set/:id"
                element={
                  <DesignItems
                    isAuthenticated={userInformationsData?.isAuthenticated}
                    isLoadingUserInformations={isLoadingUserInformations}
                    isFetchingUserInformations={isFetchingUserInformations}
                    userInformationsError={userInformationsError}
                  />
                }
              >
                <Route
                  path="item/:itemId"
                  element={<DetailPage userInformations={userInformationsData} />}
                />
              </Route>
            </Route>
          ) : (
            ""
          )}
          <Route path="/openConnection" element={<OpenConnection />} />
          <Route path="*" element={<p>Path not resolved</p>} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
