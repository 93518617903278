import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DesignItemWithPicture, PaginatedConDesignItem } from "../../models/designItemModel";
import { DesignSet } from "../../models/designSet";
import { UserInformations } from "../../models/userInformationsModel";
import { User } from "../../models/userModel";

interface Filters {
  paginationToken: string | null;
  id: string | null;
  searchQueryFilter: string | null;
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    fetchDesignSets: builder.query<DesignSet[], boolean>({
      query() {
        return `/Home/GetDesignSets`;
      },
    }),
    fetchDesignItems: builder.query<PaginatedConDesignItem, Filters>({
      query(filters) {
        return `/Home/GetDesignItemsWithPictures${"?id=" + filters.id}
        ${filters.searchQueryFilter ? "&searchQueryFilter=" + filters.searchQueryFilter : ""}
        ${filters.paginationToken ? "&paginationToken=" + filters.paginationToken : ""}`;
      },
    }),
    fetchDesignItem: builder.query<DesignItemWithPicture, string>({
      query(itemId) {
        return `/Home/GetDesignItemWithPicture?designItemId=${itemId}`;
      },
    }),
    fetchUserInformations: builder.query<UserInformations, void>({
      query() {
        return `/Home/GetUserInformations`;
      },
    }),
    fetchUser: builder.query<User, boolean>({
      query() {
        return `/User/GetUser`;
      },
    }),
  }),
});

export const {
  useFetchDesignSetsQuery,
  useFetchDesignItemsQuery,
  useFetchDesignItemQuery,
  useFetchUserInformationsQuery,
  useFetchUserQuery,
} = apiSlice;
