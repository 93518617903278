import { FC, ReactNode, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

interface IconProps {
  icon?: JSX.Element;
  link?: string;
  label?: string | null;
  children?: ReactNode;
  auth?: boolean;
  isAuthenticated?: boolean;
  id?: string | null | undefined;
}

const NavItem: FC<IconProps> = (props) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const content = (
    <>
      {props.label && props.auth ? (
        <IconContext.Provider value={{ color: "#f36e22" }}>{props.icon}</IconContext.Provider>
      ) : (
        <IconContext.Provider value={{ color: "#ffffff" }}>{props.icon}</IconContext.Provider>
      )}

      <label className="link-label">{props.label}</label>
      {props.label ? (
        <IconContext.Provider value={{ color: "#f36e22" }}>
          <AiOutlineDown size={15} />
        </IconContext.Provider>
      ) : (
        ""
      )}
    </>
  );

  return (
    <li className="nav-item">
      {props.auth ? (
        <a
          href={props.link ? props.link : "#"}
          className="nav-item-link"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {content}
        </a>
      ) : (
        <Link
          to={props.link ? props.link : "#"}
          className="nav-item-link"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {content}
        </Link>
      )}

      {openDropdown && props.children}
    </li>
  );
};

export default NavItem;
