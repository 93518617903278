import React, { FC } from 'react';
import { IconContext } from 'react-icons';
import { BiErrorCircle } from 'react-icons/bi';

interface ErrorMessage {
  message: string;
}

const Message: FC<ErrorMessage> = ({ message }) => {
  return (
    <div className='message'>
      <IconContext.Provider value={{ color: '#f75b4b' }}>
        <BiErrorCircle size={20} />
      </IconContext.Provider>
      <p className='message-content-text'>{message}</p>
    </div>
  );
};

export default Message;
