import { AiOutlineSearch } from "react-icons/ai";
import { IconContext } from "react-icons";
import React, { useState } from "react";
import { setSearchQueryFilter } from "../../../services/searchQueryFilter/searchQueryFilterSlice";
import { useAppDispatch } from "../../../app/hooks";
import { clearDesignItems } from "../../../services/designItems/designItemsSlice";

const SearchBar = () => {
  const [inputValue, setInputValue] = useState("");

  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <div className="search-bar">
      <input type="text" className="search-bar-text" onChange={handleChange}></input>
      <div
        className="search-bar-icon"
        onClick={() => {
          dispatch(setSearchQueryFilter(inputValue));
          dispatch(clearDesignItems());
        }}
      >
        <IconContext.Provider value={{ color: "#f36e22" }}>
          <AiOutlineSearch />
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default SearchBar;
