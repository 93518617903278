import { ReactComponent as Logo } from "../../../assets/logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <Logo style={{ height: 20 }} />
        </div>
        <div className="footer-menu"></div>
      </div>
    </div>
  );
};

export default Footer;
