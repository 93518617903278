import { FC } from "react";

interface LoaderProps {
  bottom?: boolean;
}

const Loader: FC<LoaderProps> = ({ bottom }) => {
  return (
    <div className={bottom ? "spinner bottom" : "spinner"}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loader;
