import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DesignItemWithPicture } from "../../models/designItemModel";

interface DesignItemsSliceState {
  items: DesignItemWithPicture[];
}

const initialState: DesignItemsSliceState = {
  items: [],
};

export const designItemsSlice = createSlice({
  name: "designItems",
  initialState,
  reducers: {
    setDesignItems: (state, action: PayloadAction<DesignItemWithPicture[]>) => {
      state.items = state.items.concat(action.payload);
    },
    clearDesignItems: (state) => {
      state.items = [];
    },
  },
});

export const { setDesignItems, clearDesignItems } = designItemsSlice.actions;

export const selectItems = (state: RootState) => state.items.items;
