import { FC } from "react";
import DropdownItem from "../DropDownItem";

interface DropdownItemsProps {
  link: string;
  label?: string | undefined;
}

const DropdownMenu: FC<DropdownItemsProps> = (props) => {
  return (
    <div className="dropdown">
      <DropdownItem link={props.link} auth={true}>
        {props.label}
      </DropdownItem>
    </div>
  );
};

export default DropdownMenu;
