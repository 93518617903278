import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectItem } from "../../../services/detailPage/detailPageSlice";
import { setEnvironmentType } from "../../../services/environmentType/environmentTypeSlice";
import { setUserInformations } from "../../../services/openConnection/openConnectionSlice";
import { UserInformations } from "../../../models/userInformationsModel";
import { Outlet } from "react-router-dom";
import Message from "../../common/Message";

interface UserInformationsProps {
  userInformationsData: UserInformations | undefined;
}

const Home: FC<UserInformationsProps> = ({ userInformationsData }) => {
  const designItemWithPicture = useAppSelector(selectItem);
  const dispatch = useAppDispatch();

  const urlString = window.location.href;

  useEffect(() => {
    if (urlString.indexOf("staging") > -1) {
      dispatch(setEnvironmentType("staging"));
    } else if (urlString.indexOf("develop") > -1) {
      dispatch(setEnvironmentType("develop"));
    } else if (urlString.indexOf("localhost") > -1) {
      dispatch(setEnvironmentType("local"));
    } else {
      dispatch(setEnvironmentType("production"));
    }
  }, [dispatch, urlString]);

  useEffect(() => {
    if (userInformationsData) {
      dispatch(setUserInformations(userInformationsData));
    }
  }, [userInformationsData, dispatch]);

  return (
    <div className={designItemWithPicture ? "home home-detail" : "home"}>
      {!userInformationsData?.isAuthenticated ? <Message message="You need to sign in" /> : ""}
      <Outlet />
    </div>
  );
};

export default Home;
