import { FC } from "react";
import { Link } from "react-router-dom";

interface DropdownItemProps {
  children?: string;
  link: string;
  id?: string | undefined | null;
  auth?: boolean;
}

const DropdownItem: FC<DropdownItemProps> = (props) => {
  return (
    <>
      {props.auth ? (
        <a href={props.link} className="menu-item">
          {props.children}
        </a>
      ) : (
        <Link to={props.link} className="menu-item">
          {props.children}
        </Link>
      )}
    </>
  );
};

export default DropdownItem;
