import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserInformations } from "../../models/userInformationsModel";

export interface UserInformationsSliceState {
  userInformations: UserInformations | null;
}

const initialState: UserInformationsSliceState = {
  userInformations: null,
};

export const openConnectionSlice = createSlice({
  name: "openConnection",
  initialState,
  reducers: {
    setUserInformations: (state, action: PayloadAction<UserInformations>) => {
      state.userInformations = action.payload;
    },
    clearUserInformations: (state) => initialState,
  },
});

export default openConnectionSlice.reducer;

export const { setUserInformations, clearUserInformations } = openConnectionSlice.actions;

export const selectUserInformations = (state: RootState) => state.userInformations.userInformations;
