import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { detailPageSlice } from '../services/detailPage/detailPageSlice';
import { apiSlice } from '../services/wcbApi/wcbApiSlice';
import { environmentTypeSlice } from '../services/environmentType/environmentTypeSlice';
import { openConnectionSlice } from '../services/openConnection/openConnectionSlice';
import { paginationTokenSlice } from '../services/paginationToken/paginationTokenSlice';
import { designItemsSlice } from '../services/designItems/designItemsSlice';
import { designSetSlice } from '../services/designSet/designSetSlice';
import { searchQueryFilterSlice } from '../services/searchQueryFilter/searchQueryFilterSlice';


export const rootReducer = combineReducers({
  set: designSetSlice.reducer,
  item: detailPageSlice.reducer,
  environmentType: environmentTypeSlice.reducer,
  userInformations: openConnectionSlice.reducer,
  paginationToken: paginationTokenSlice.reducer,
  items: designItemsSlice.reducer,
  searchQueryFilter: searchQueryFilterSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
    preloadedState
  })
}


export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch'];
