import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface PaginationTokenSliceState {
  paginationToken: string | null;
}

const initialState: PaginationTokenSliceState = {
  paginationToken: null,
};

export const paginationTokenSlice = createSlice({
  name: "paginationToken",
  initialState,
  reducers: {
    setPaginationToken: (state, action: PayloadAction<string>) => {
      state.paginationToken = action.payload;
    },
    clearPaginationToken: (state) => {
      state.paginationToken = null;
    },
  },
});

export default paginationTokenSlice.reducer;

export const { setPaginationToken, clearPaginationToken } = paginationTokenSlice.actions;

export const selectPaginationToken = (state: RootState) => state.paginationToken.paginationToken;
