import { useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectUserInformations } from "../../../services/openConnection/openConnectionSlice";

const OpenConnection = () => {
  const userInformations = useAppSelector(selectUserInformations);

  useEffect(() => {
    if (userInformations?.userIds.licenseId! && userInformations.userIds.userId) {
      window.dataLayer.push({
        "event": "wcb_open_to_desktop_page_shown",
        "license_id": userInformations.userIds.licenseId,
        "user_id": userInformations.userIds.userId,
      });
    }
  }, [userInformations]);

  useEffect(() => {
    if (userInformations?.userIds.licenseId! && userInformations.userIds.userId) {
      window.dataLayer.push({
        "event": "wcb_idea_application_downloaded",
        "license_id": userInformations.userIds.licenseId,
        "user_id": userInformations.userIds.userId,
      });
    }
  }, [userInformations]);

  return (
    <div className="openConnection">
      <div className="openConnection-container">
        <p className="openConnection-text">You don't have installed desktop application yet ?</p>
        <button className="button-primary">
          <a
            href="https://marketing.ideastatica.com/idea-statica-download"
            target="_blank"
            className="button-primary-text"
            rel="noreferrer"
          >
            Download
          </a>
        </button>
      </div>
    </div>
  );
};

export default OpenConnection;
