import { FC } from "react";
import { DesignSet } from "../../../models/designSet";
import DropdownItem from "../DropDownItem";

interface DesignSetsDropdownProps {
  designSets: DesignSet[] | undefined;
}

const DesignSetsDropdown: FC<DesignSetsDropdownProps> = (props) => {
  return (
    <div className="design-sets-dropdown">
      {props.designSets?.map((set, index) => (
        <DropdownItem link={`/set/${set.id}`} id={set.id} key={index}>
          {set.name}
        </DropdownItem>
      ))}
    </div>
  );
};

export default DesignSetsDropdown;
