import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface EnvironmentTypeSliceState {
  environmentType: string | null;
}

const initialState: EnvironmentTypeSliceState = {
  environmentType: null,
};

export const environmentTypeSlice = createSlice({
  name: "environmentType",
  initialState,
  reducers: {
    setEnvironmentType: (state, action: PayloadAction<string>) => {
      state.environmentType = action.payload;
    },
    clearEnvironmentType: (state) => {
      state.environmentType = null;
    },
  },
});

export default environmentTypeSlice.reducer;

export const { setEnvironmentType, clearEnvironmentType } = environmentTypeSlice.actions;

export const selectEnvironmentType = (state: RootState) => state.environmentType.environmentType;
