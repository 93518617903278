import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface DesignSetSliceState {
  id: string | null;
}

const initialState: DesignSetSliceState = {
  id: null,
};

export const designSetSlice = createSlice({
  name: "designSet",
  initialState,
  reducers: {
    setDesignSetId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    clearDesignSetId: (state) => {
      state.id = null;
    },
  },
});

export default designSetSlice.reducer;

export const { setDesignSetId, clearDesignSetId } = designSetSlice.actions;

export const selectSet = (state: RootState) => state.set.id;
export const selectState = (state: RootState) => state;
