import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
import { FC, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearDesignItems,
  selectItems,
  setDesignItems,
} from "../../../services/designItems/designItemsSlice";
import { selectSet, setDesignSetId } from "../../../services/designSet/designSetSlice";
import {
  clearPaginationToken,
  selectPaginationToken,
  setPaginationToken,
} from "../../../services/paginationToken/paginationTokenSlice";
import {
  clearSearchQueryFilter,
  selectSearchQueryFilter,
} from "../../../services/searchQueryFilter/searchQueryFilterSlice";
import { useFetchDesignItemsQuery } from "../../../services/wcbApi/wcbApiSlice";
import Loader from "../../common/Loader";
import Message from "../../common/Message";

interface DesignItemsProps {
  isAuthenticated: boolean | undefined;
  isLoadingUserInformations: boolean;
  isFetchingUserInformations: boolean;
  userInformationsError: FetchBaseQueryError | SerializedError | undefined;
}

const DesignItems: FC<DesignItemsProps> = ({
  isAuthenticated,
  isLoadingUserInformations,
  isFetchingUserInformations,
  userInformationsError,
}) => {
  const dispatch = useAppDispatch();

  const designSetId = useAppSelector(selectSet);

  const { id, itemId } = useParams();

  useEffect(() => {
    if (id) dispatch(setDesignSetId(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(clearDesignItems());
    dispatch(clearPaginationToken());
    dispatch(clearSearchQueryFilter());
  }, [id, dispatch]);

  const paginationToken = useAppSelector(selectPaginationToken);

  const searchQueryFilter = useAppSelector(selectSearchQueryFilter);

  const {
    data: designItemsData,
    error: designItemsError,
    isLoading: isLoadingDesignItems,
    isFetching: isFetchingDesignItems,
  } = useFetchDesignItemsQuery(
    designSetId
      ? { searchQueryFilter: searchQueryFilter, paginationToken: paginationToken, id: designSetId }
      : skipToken,
  );

  useEffect(() => {
    if (designItemsData?.designItems) {
      dispatch(setDesignItems(designItemsData.designItems));
    }
  }, [dispatch, designItemsData]);

  const designItems = useAppSelector(selectItems);

  const handleRefetchData = () => {
    if (designItemsData?.paginationToken) {
      const encodedPaginationToken = encodeURIComponent(designItemsData.paginationToken);
      dispatch(setPaginationToken(encodedPaginationToken));
    }
  };

  return (
    <>
      {designItemsError ? <Message message="Items not found, try again later" /> : ""}
      {designItemsData?.designItems.length === 0 ? <Message message="Items not found" /> : ""}

      <div className={itemId ? `home-container home-container-detail` : `home-container`}>
        {designItems.map((item, index) => (
          <Link to={`/set/${designSetId}/item/${item.designItem.conDesignItemId}`} key={index}>
            <div className="home-card">
              <img src={item.pictureUrl} className="home-card-image" alt="connection thumbnail" />
            </div>
          </Link>
        ))}
      </div>
      <div className={itemId ? `show-more show-more-detail` : `show-more`}>
        {isAuthenticated &&
          (isLoadingDesignItems ||
            isFetchingDesignItems ||
            isLoadingUserInformations ||
            isFetchingUserInformations) &&
          !userInformationsError &&
          !designItemsError && (
            <div className="home-action-container">
              <Loader bottom />
            </div>
          )}
        {designItemsData?.paginationToken && !isLoadingDesignItems && !isFetchingDesignItems ? (
          <button className="button-primary" onClick={handleRefetchData}>
            Show more
          </button>
        ) : (
          ""
        )}
      </div>

      <Outlet />
    </>
  );
};

export default DesignItems;
