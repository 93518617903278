import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DesignItemWithPicture } from "../../models/designItemModel";

export interface DesignItemWithPictureSliceState {
  item: DesignItemWithPicture | null;
}

const initialState: DesignItemWithPictureSliceState = {
  item: null,
};

export const detailPageSlice = createSlice({
  name: "detailPage",
  initialState,
  reducers: {
    setDesignItem: (state, action: PayloadAction<DesignItemWithPicture>) => {
      state.item = action.payload;
    },
    clearDesignItem: (state) => {
      state.item = null;
    },
  },
});

export default detailPageSlice.reducer;

export const { setDesignItem, clearDesignItem } = detailPageSlice.actions;

export const selectItem = (state: RootState) => state.item.item;
