import { FC } from "react";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { UserInformations } from "../../../models/userInformationsModel";
import { BsPerson } from "react-icons/bs";
import NavItem from "../../common/navItem";
import DropdownMenu from "../../common/DropdownMenu";
import { User } from "../../../models/userModel";
import { DesignSet } from "../../../models/designSet";
import DesignSetsDropdown from "../../common/DesignSetsDropdown";
import SearchBar from "../../common/SearchBar";

interface UserInformationsProps {
  userInformationsData: UserInformations | undefined | null;
  user: User | undefined;
  designSetsData: DesignSet[] | undefined;
}

const Header: FC<UserInformationsProps> = ({ userInformationsData, user, designSetsData }) => {
  return (
    <div className="header">
      <div className="header-container">
        <a href="/" className="header-logo">
          <Logo />
        </a>
        <ul className="header-menu">
          <>
            {userInformationsData?.isAuthenticated === true && user ? (
              <>
                <NavItem label="Design Sets">
                  <DesignSetsDropdown designSets={designSetsData} />
                </NavItem>
                <SearchBar />
              </>
            ) : (
              ""
            )}

            {userInformationsData?.isAuthenticated === true ? (
              <NavItem icon={<BsPerson />} label={user?.email} auth>
                <DropdownMenu link="/account/logout" label="Logout" />
              </NavItem>
            ) : (
              <NavItem
                icon={<BsPerson className="nav-item-icon" />}
                link="/account/login"
                auth={true}
              />
            )}
          </>
        </ul>
      </div>
    </div>
  );
};

export default Header;
